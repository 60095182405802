<template>
  <div class="bg py-15" :class="type" :id="id">
    <v-container fluid>
      <slot />
    </v-container>
  </div>
</template>

<script>
export default {
  name: "container",
  props: {
    id: String,
    type: String,
  },
};
</script>

<style lang="scss">
.bg {
  position: relative;
  z-index: 1;
}
</style>
