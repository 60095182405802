<template>
  <v-row>
    <v-col :cols="12" :md="4">
      <component :is="titleSize">{{ title }}</component>
    </v-col>
    <v-col :cols="12" :md="8">
      <ul class="list-plain split-two">
        <li v-for="(item, id) in serviceList" :key="id">{{ item }}</li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ServiceList",
  props: {
    title: {
      type: String,
      default: "Our Services Include",
    },
    titleSize: {
      type: String,
      default: "h3",
    },
    serviceList: Array,
  },
};
</script>
