import Vue from "vue";
import VueCookies from 'vue-cookies'
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import VideoBackground from "vue-responsive-video-background-player";
import Container from "@/components/Container.vue";
import TitleAndDescription from "@/components/blocks/TitleAndDescription.vue";
import ServicesList from "@/components/blocks/ServicesList.vue";

var VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

Vue.component("video-background", VideoBackground);

// Custom Components
Vue.component("container", Container);
Vue.component("title-and-description", TitleAndDescription);
Vue.component("services-list", ServicesList);

Vue.use(require("vue-moment"));
Vue.use(VueCookies);

import DefaultLayout from "./layout/DefaultLayout.vue";

import "@/styles/index.scss";

Vue.component("default-layout", DefaultLayout);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
