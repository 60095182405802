<template>
  <ul class="social-links" :class="direction">
    <li v-for="(item, id) in social" :key="id">
      <a :href="item.link" target="_blank" :title="item.name"
        ><v-icon>{{ item.icon }}</v-icon></a
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: "SocialLinks",
  props: {
    direction: String,
  },
  data() {
    return {
      social: [
        {
          name: "Facebook",
          icon: "fab fa-facebook-f",
          link: "https://www.facebook.com/pages/DPQ-Communications/127061870709677",
        },
        {
          name: "Instagram",
          icon: "fab fa-instagram",
          link: "http://instagram.com/qcomms/",
        },
        {
          name: "Youtube",
          icon: "fab fa-youtube",
          link: "https://www.youtube.com/channel/UCzKaXRwJqCEEP7wYhkFRmhA?view_as=subscriber",
        },
        {
          name: "Linkedin",
          icon: "fab fa-linkedin-in",
          link: "https://www.linkedin.com/company/dpq?report.success=KJ_KkFGTDCfMt-A7wV3Fn9Yvgwr02Kd6AZHGx4bQCDiP6-2rfP2oxyVoEQiPrcAQ7Bf",
        },
      ],
    };
  },
};
</script>
