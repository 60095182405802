<template>
  <div
    class="footer py-10 overlay"
    id="contact"
    :style="{ 'background-image': 'url(' + footerImage + ')' }"
  >
    <v-container fluid>
      <v-row>
        <v-col class="text-center">
          <img src="@/assets/logo.png" />
          <h2 class="py-5">Get In Touch With Us!</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" v-for="(item, id) in locations" :key="id">
          <v-card outlined tile dark class="pa-4 v-card-footer fill-height">
            <v-card-title>{{ item.city }}</v-card-title>
            <v-card-text>
              {{ item.address_1 }}
              <br />{{ item.address_2 }} <br />{{ item.address_3 }} <br />{{
                item.country
              }}
            </v-card-text>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>{{ item.contact_name }}</v-list-item-title>
                <v-list-item-subtitle>
                  <a :href="'mailto:' + item.email">{{ item.email }}</a>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.phone }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <hr />
      <v-row style="align-items: center">
        <v-col cols="12" md="6"
          >&copy; {{ new Date().getFullYear() }} Q Communications. All Rights
          Reserved.</v-col
        >
        <v-col cols="!2" md="6" class="text-end"
          >Follow Us <social-links direction="horizontal"
        /></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SocialLinks from "../../components/SocialLinks.vue";

export default {
  name: "Footer",
  components: { SocialLinks },
  data() {
    return {
      footerImage: require("@/assets/backgrounds/footer-1.jpg"),
      locations: [
        {
          city: "Dubai",
          address_1: "Jumeirah Lake Towers",
          address_2: "Cluster Y,",
          address_3: "Swiss Tower, Office 2403,",
          country: "Dubai",
          contact_name: "Elsa Roodt",
          email: "elsa@qcomms.ae",
          phone: "+971 50 450 2969",
        },
        {
          city: "Manchester",
          address_1: "28 Kansas Avenue,",
          address_2: "Media City,",
          address_3: "M50 2GL,",
          country: "United Kingdom",
          contact_name: "Kate Burke",
          email: "kate@qcommunications.co.uk",
          phone: "+44 788 922 3906",
        },
        {
          city: "Abu Dhabi",
          address_1: "twofour54 Park",
          address_2: "Rotana Office Complex,",
          address_3: "Office 902C",
          country: "Abu Dhabi",
          contact_name: "Katie Harvey",
          email: "katie@qcomms.ae",
          phone: "+971 56 170 4816",
        },
      ],
    };
  },
};
</script>

<style lang="scss"></style>
