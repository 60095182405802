<template>
  <v-app>
    <router-view />
    <cookie-policy/>
    <v-footer />
  </v-app>
</template>

<script>
import vFooter from "./includes/Footer.vue";
import CookiePolicy from "../components/CookiePolicy.vue"

export default {
  name: "DefaultLayout",
  components: {
    vFooter,
    CookiePolicy
  },
};
</script>
