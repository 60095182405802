<template>
    <div v-if="!cookiePolicyAccepted" class="cookie-policy pa-10">
        <span>We use cookies to improve your experience on our website. By continuing to browse, you agree to our use of cookies.</span>
        <div>
            <v-btn 
                color="primary"
                title
                large 
                @click="acceptCookies"
            >
                Accept
            </v-btn>
        </div>
        
    </div>
  </template>
  
<script>
    export default {
        name: "CookiePolicy",
        props: {
            id: String,
            type: String,
        },
        data() {
            return {
                cookiePolicyAccepted: false,
            }
        },
        created() {
            this.cookiePolicyAccepted = this.$cookies.isKey('cp')
        },
        methods: {
            acceptCookies() {
                this.$cookies.set('cp', true)
                this.cookiePolicyAccepted = true
            }
        }
    };
</script>
  
<style lang="scss">
.cookie-policy {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: #17181D;
    text-align: center;
}

.cookie-policy span {
    color: #fff;
    margin: 0 10px;
}

@media only screen and (max-width: 600px) {
    .cookie-policy {
        flex-direction: column;
    }

    .cookie-policy span {
        margin: 10px 0;
    }
}
</style>
  