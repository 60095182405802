<template>
  <v-row>
    <v-col :md="4">
      <component :is="titleSize">{{ title }}</component>
    </v-col>
    <v-col :md="8">
      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TitleAndDescription",
  props: {
    title: String,
    titleSize: {
      type: String,
      default: "h1",
    },
  },
};
</script>
